import React, { Suspense, lazy, Fragment, useContext, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  HashRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useParams
} from "react-router-dom";

import API from './helpers/API';

import { useAuth } from "./Context/AuthContext";

import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";



import { AdminRoute } from "./routes/AdminRoute";

import { ApplicantRoute } from "./routes/ApplicantRoute";
import { EmployerRoute } from "./routes/EmployerRoute";

import PrivateRoute from "./routes/PrivateRoute";
import ProtectRoute from "./routes/PrivateRoute";

const Home = lazy(() => import("./features/Home/Home"));
const About = lazy(() => import("./features/AboutUs/About-Us"));
const OurMission = lazy(() => import("./features/AboutUs/OurMission/Our-Mission"));
const OurVision = lazy(() => import("./features/AboutUs/OurVision/Our-Vision"));

const Employers = lazy(() => import("./features/Employers/Employers"));

const Jobs = lazy(() => import("./features/Jobs/Jobs"));
const JobDetails = lazy(() => import("./features/Jobs/JobDetails"));


const ContactUs = lazy(() => import("./features/Contact/Contact-Us"));
const ThankYou = lazy(() => import("./features/Contact/ThankYou"));
const ResumeUpload = lazy(() => import("./features/ResumeUpload/Upload-Resume"));


const Login = lazy(() => import("./features/Account/Login/Login"));
const Register = lazy(() => import("./features/Account/Register/Register"));

const EmailVerification = lazy(() => import('./features/Account/EmailConfirmation/EmailVerification'));

// Apply Job Form
const ApplyJobForm = lazy(() => import("./features/Account/ApplyJob/ApplyJobForm"));

// UnAuthorized Page
const UnAuthorized = lazy(() => import('./features/UnAuthorized/UnAuthorized'));


// Password Resources
const ForgotPasswordReset = lazy(() => import("./features/Account/ForgotPasswordReset/ForgotPasswordReset"));
const ResetPassword = lazy(() => import("./features/Account/ForgotPasswordReset/ResetPassword"));


// Admin Resources
const AdminDashboardHome = lazy(() => import('./admin/Home'));
const PostJob = lazy(() => import("./admin/Jobs/PostJob"));
const UpdateJob = lazy(() => import("./admin/Jobs/UpdateJob"));
const DeleteJob = lazy(() => import("./admin/Jobs/DeleteJob"));
const ManageJobs = lazy(() => import("./admin/Jobs/ManageJobs"));
const AppliedJobs = lazy(() => import("./admin/Jobs/AppliedJobs"));

const AddCountry = lazy(() => import("./admin/Countries/AddCountry"));
const ManageCountries = lazy(() => import("./admin/Countries/ManageCountries"));

const AddQualification = lazy(() => import("./admin/Qualifications/AddQualification"));
const ManageQualifications = lazy(() => import("./admin/Qualifications/ManageQualifications"));

const AddWorkExperience = lazy(() => import("./admin/WorkExperiences/AddWorkExperience"));
const ManageWorkExperiences = lazy(() => import("./admin/WorkExperiences/ManageWorkExperiences"));

const AddWorkMode = lazy(() => import("./admin/WorkModes/AddWorkMode"));
const ManageWorkModes = lazy(() => import("./admin/WorkModes/ManageWorkModes"));

const AddProvince = lazy(() => import("./admin/Provinces/AddProvince"));
const ManageProvinces = lazy(() => import("./admin/Provinces/ManageProvinces"));

const AddCity = lazy(() => import("./admin/Cities/AddCity"));
const ManageCities = lazy(() => import("./admin/Cities/ManageCities"));

const AddSector = lazy(() => import("./admin//Sectors/AddSector"));
const ManageSectors = lazy(() => import("./admin/Sectors/ManageSectors"));

const AddWorkAuthorization = lazy(() => import("./admin/WorkAuthorizations/AddWorkAuthorization"));
const ManageWorkAuthorizations = lazy(() => import("./admin/WorkAuthorizations/ManageWorkAuthorizations"));

const AddSalary = lazy(() => import("./admin/Salaries/AddSalary"));
const ManageSalaries = lazy(() => import("./admin/Salaries/ManageSalaries"));


const ManageContactMessages = lazy(() => import("./admin/ContactMessages/ManageContactMessages"));
const ViewContactMessage = lazy(() => import("./admin/ContactMessages/ViewContactMessage"));
const DeleteContactMessage = lazy(() => import("./admin//ContactMessages/DeleteContactMessage"));

const AdminChangePassword = lazy(() => import("./admin/ChangePassword/ChangePassword"));


// Applicant Private Resources
const ApplicantDashboardHome = lazy(() => import('./applicant/Home'));
const ApplicantProfile = lazy(() => import("./applicant/ApplicantProfiles/ApplicantProfile"));
const ApplicantChangePassword = lazy(() => import("./applicant/ChangePassword/ChangePassword"));
const ProfileSetting = lazy(() => import("./applicant/ApplicantProfiles/ProfileSetting"));
const AcademicQualification = lazy(() => import("./applicant/Education/AcademicQualification"));
const AddEducation = lazy(() => import("./applicant/Education/AddEducation"));
const DeleteEducation = lazy(() => import("./applicant//Education/DeleteEducation"));
const DeleteSkill = lazy(() => import("./applicant/Skill/DeleteSkill"));
const AddJobExperience = lazy(() => import("./applicant/Experience/AddJobExperience"));
const DeleteApplicantExperience = lazy(() => import("./applicant/Experience/DeleteApplicatExperience"));
const DeleteResume = lazy(() => import("./applicant/Resume/DeleteResume"));


// Employer Private Resources
const EmployerDashboardHome = lazy(() => import("./employer/Home"));
const EPostJob = lazy(() => import("./employer/Jobs/PostJob"));
const EManageJobs = lazy(() => import("./employer/Jobs/EManageJobs"));
const EAppliedJobs = lazy(() => import("./employer/Jobs/EAppliedJobs"));

const Loader = () => (
  <div className="container text-center">
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

function App() {

  const StyledContainer = styled(ToastContainer)`
        &&&.Toastify__toast-container {
        }
        .Toastify__toast {
        width: 550px !important;
        height: 200px;
        font-family: Trebuchet-ms;
        font-weight: bold;
        }
        .Toastify__toast-body {
        width: 550px !important;
        height: 200px;
        font-family: Trebuchet-ms;
        }
        .Toastify__progress-bar {    
        }
  `;

  const [auth, setAuth] = useAuth();
  const [user, setUser] = useState({});
  const params = useParams();


const fetchRegUserById = async () => {
  try 
  {
    const { data } = await API.get(`/api/v1/users/fetchRegUserById/${params._id}`);
    setUser(data.user);
  } catch (error) {
    console.log(error);
  }
};

  // initial Registered User Details
// Fetch Registered User Details
useEffect(() => {
  if (params?._id) fetchRegUserById();
}, [params?._id]);

useEffect(() => {
  const userId = JSON.parse(localStorage.getItem("userAuthDetails"));
  if (userId) {
    setUser(userId);
  }
}, []);

  return (
    <div className="container-fluid bg-white p-0 App">
      
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About-Us" element={<About />} />
          <Route path="/About-Us/Our-Mission" element={<OurMission />} />
          <Route path="/About-Us/Our-Vision" element={<OurVision />} />
          <Route path="/Employers" element={<Employers />} />
          <Route path="/Browse-Jobs" element={<Jobs />} />
          <Route path="/Job-Details/:slug" element={<JobDetails />} />
          

          <Route path="/Contact-Us" element={<ContactUs />} />
          <Route path="/Thank-You-For-Contacting-Us" element={<ThankYou />} />

          {/* <Route path="/Resume-Upload" element={<ResumeUpload />} /> */}

          <Route path="/Login" element={<Login />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/:id/verify/:token" element={<EmailVerification />} />

          <Route path="/Account/Forgot-Password" element={<ForgotPasswordReset />} />
          <Route path="/reset-password/:id/:resetToken" element={<ResetPassword />} />

          {/* <Route path="/Admin/Home" element={<AdminHome />} />
          */}
          {/* <Route path="/Admin/Dashboard" element={<AdminDashboardHome />} /> */}
          
          {/* <Route path="/Admin/Jobs/Manage-Jobs" element={<ManageJobs />} /> 
          <Route path="/Admin/Jobs/Applied-Jobs" element={<AppliedJobs />} /> 

          <Route path="/Admin/Countries/Add-Country" element={<AddCountry />} /> 
          <Route path="/Admin/Countries/Manage-Countries" element={<ManageCountries />} /> 

          <Route path="/Admin/Sectors/Add-Sector" element={<AddSector />} /> 
          <Route path="/Admin/Sectors/Manage-Sectors" element={<ManageSectors />} /> 

          <Route path="/Admin/WorkAuthorizations/Add-Work-Authorization" element={<AddWorkAuthorization />} />
          <Route path="/Admin/WorkAuthorizations/Manage-Work-Authorizations" element={<ManageWorkAuthorizations />} /> */}


          {/* Admin Routes */}
          <Route path="/" element={<AdminRoute />}>
            <Route path="/Admin/Dashboard" element={<AdminDashboardHome />} />
            <Route path="/Admin/Jobs/Post-Job" element={<PostJob />} /> 
            <Route path="/Admin/Jobs/Update-Job/:slug" element={<UpdateJob />} />
            <Route path="/Admin/Jobs/Delete-Job/:slug" element={<DeleteJob />} />
            <Route path="/Admin/Jobs/Manage-Jobs" element={<ManageJobs />} /> 
            <Route path="/Admin/Jobs/Applied-Jobs" element={<AppliedJobs />} /> 

            <Route path="/Admin/Qualifications/Add-Qualification" element={<AddQualification />} />
            <Route path="/Admin/Qualifications/Manage-Qualifications" element={<ManageQualifications />} />

            <Route path="/Admin/WorkExperiences/Add-Work-Experience" element={<AddWorkExperience />} />
            <Route path="/Admin/WorkExperiences/Manage-Work-Experiences" element={<ManageWorkExperiences />} />

            <Route path="/Admin/WorkModes/Add-Work-Mode" element={<AddWorkMode />} />
            <Route path="/Admin/WorkModes/Manage-Work-Modes" element={<ManageWorkModes />} />

            <Route path="/Admin/Countries/Add-Country" element={<AddCountry />} /> 
            <Route path="/Admin/Countries/Manage-Countries" element={<ManageCountries />} /> 

            <Route path="/Admin/Provinces/Add-Province" element={<AddProvince />}  />
            <Route path="/Admin/Provinces/Manage-Provinces" element={<ManageProvinces />} />

            <Route path="/Admin/Cities/Add-City" element={<AddCity />} />
            <Route path="/Admin/Cities/Manage-Cities" element={<ManageCities />} />

            <Route path="/Admin/Sectors/Add-Sector" element={<AddSector />} /> 
            <Route path="/Admin/Sectors/Manage-Sectors" element={<ManageSectors />} /> 

            <Route path="/Admin/Salaries/Add-Salary" element={<AddSalary />} /> 
            <Route path="/Admin/Salaries/Manage-Salaries" element={<ManageSalaries />} /> 

            <Route path="/Admin/Contact-Messages/Manage-Contact-Us-Messages" element={<ManageContactMessages />} />
            <Route path="/Admin/Contact-Messages/View-Message/:id" element={<ViewContactMessage />} />
            <Route path="/Admin/Contact-Messages/Delete-Message/:id" element={<DeleteContactMessage />} />

            <Route path="/Admin/WorkAuthorizations/Add-Work-Authorization" element={<AddWorkAuthorization />} />
            <Route path="/Admin/WorkAuthorizations/Manage-Work-Authorizations" element={<ManageWorkAuthorizations />} />
            <Route path="/Account/Change-Password" element={<AdminChangePassword />} />            
          </Route>
          

          {/* Applicant Routes */}
          <Route path="/" element={<ApplicantRoute />}>
            <Route path="/Applicant/Dashboard" element={<ApplicantDashboardHome />} />
            <Route path="/Account/Apply-Job/:slug" element={<ApplyJobForm />} />
            <Route path={`/Applicant/Profile/${auth?.user?.userId}`} element={<ApplicantProfile />} />
            <Route path="/Applicant/Change-Password" element={<ApplicantChangePassword />} /> 
            <Route path="/Applicant/Profile-Setting" element={<ProfileSetting />} /> 
            <Route path={`/Applicant/Academic-Qualification/${auth?.user?.userId}`} element={<AcademicQualification />} /> 
            <Route path="/Applicant/Add-Education/:userId" element={<AddEducation />} /> 
            <Route path="/Applicant/Delete-Education/:id" element={<DeleteEducation />} />
            <Route path="/Applicant/Delete-Skill/:id" element={<DeleteSkill />} />
            <Route path="/Applicant/Add-Job-Experience" element={<AddJobExperience />} />
            <Route path="/Applicant/Delete-Applicant-Experience/:id" element={<DeleteApplicantExperience />} />
            <Route path={`/Resume-Upload/${auth?.user?.userId}`} element={<ResumeUpload />} />
            <Route path="/Applicant/Delete-Resume/:id" element={<DeleteResume />} />
          </Route>
          

          {/* Employer Routes */}
          <Route path="/" element={<EmployerRoute />}>
            <Route path="/Employer/Dashboard" element={<EmployerDashboardHome />} />
            <Route path="/Employer/Jobs/Post-Job" element={<EPostJob />} /> 
            <Route path="/Employer/Jobs/Manage-Jobs" element={<EManageJobs />} /> 
            <Route path="/Employer/Jobs/Applied-Jobs" element={<EAppliedJobs />} /> 
          </Route>

          <Route path="/PSPL-Access-Denied" element={<UnAuthorized />} />
        </Routes>
      </Suspense>
      <StyledContainer />
    </div>
  );
}

export default App;
